import { Controller } from '@hotwired/stimulus';
import Choices from 'choices.js';

const OPTIONS = {
  removeItemButton: true,
  itemSelectText: ''
};

export default class extends Controller {
  static targets = ['select'];
  static outlets = ['choices'];

  _connected = false;
  _outletConnected = false;
  _initialized = false;

  connect() {
    this._cacheOptions();
    this.choices = new Choices(this.selectTarget, { ...OPTIONS, items: this._filteredOptions(this._filterValue()) });

    if (this.hasChoicesOutlet) {
      this._connected = true;
      this._initializeWithOutlet();
    }
  }

  disconnect() {
    this.choices?.destroy();
  }

  choicesOutletConnected(outlet, element) {
    outlet.selectTarget.addEventListener('change', ({ target }) => this._handleChoicesOutletChange(target.value))
    this._outletConnected = true;
    this._initializeWithOutlet();
  }

  _initializeWithOutlet() {
    if (!this._initialized && this._connected && this._outletConnected && this.choicesOutlet.selectTarget.value) {
      this._initialized = true;
      this._handleChoicesOutletChange(this.choicesOutlet.selectTarget.value);
    }
  }

  _handleChoicesOutletChange(value = '') {
    const selected = this._filterValue();
    this.choices.setChoiceByValue('');

    if (value === '') {
      this.choices.setChoices(this.options, 'value', 'label', true);
    } else {
      this.choices.setChoices(this.options.filter((option) => option.value.startsWith(`${value}-`)), 'value', 'label', true, true);
    }

    this.choices.setChoiceByValue(selected);
  }

  _filteredOptions(value = '') {
    if (!value) return this.options;

    return this.options.filter((option) => option.value === '' || option.value.startsWith(`${value}-`));
  }

  _cacheOptions() {
    this.options = Array.from(this.selectTarget.options).map((option) => ({
      value: option.value,
      label: option.text,
      selected: option.selected
    }));
  }

  _filterValue() {
    return this.hasChoicesOutlet ? this.choicesOutlet.selectTarget.value : '';
  }
}
